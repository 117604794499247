<template>
  <AuthWrapper>
    <template #card>
      <div class="text-center">
        <h3 class="py-5">
          You've been already registered to this service
        </h3>
      </div>
      <div class="text-center">
        <b-link :to="{ name: 'app-root'}">
          <b-button variant="primary" block>
            Learn More
          </b-button>
        </b-link>
      </div>
    </template>
  </AuthWrapper>
</template>

<script>
import { BButton, BLink } from "bootstrap-vue";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";

export default {
  name: "Registered",
  components: {
    AuthWrapper,
    BButton,
    BLink
  }
}
</script>

<style scoped>

</style>
